export const environment = {
  production: true,
  tel: "34733131",
  area: "35",
  hasCashback: false,
  hasRecharge: false,
  rechargeLimit: 100.0,
  rechargeBalanceLimit: 500.0,
  url: "https://api.dev.reconline.nowigo.com.br",
};
